/**
 * Blaze Integrations API constants
 */

export const APP_DISPLAY_NAME = 'SNB Integrations'

/**
 * HTTP Authorization Header prefix
 */
export const AUTHORIZATION_HEADER_PREFIX = 'Bearer'

/**
 * Permission strings are used for client access control.
 * Permission strings are included in the auth token payload.
 *
 * Permissions guard access to the resource as well as the resources data.
 *
 * Permission strings ending in _OWN allow the function to be performed
 * only on data owned by the relevant resource and the clients affiliation.
 *
 * Permission strings ending in _ALL allow the function to be performed
 * on all data owned by the resource.
 */

// applications resource permissions
export const PERM_APPL_QUERY_ALL = 'APPL_QUERY_ALL'
export const PERM_APPL_QUERY_OWN = 'APPL_QUERY_OWN'
export const PERM_APPL_VALIDATE_ALL = 'APPL_VALIDATE_ALL'
export const PERM_APPL_VALIDATE_OWN = 'APPL_VALIDATE_OWN'
export const PERM_APPL_WRITE_ALL = 'APPL_WRITE_ALL'
export const PERM_APPL_WRITE_OWN = 'APPL_WRITE_OWN'

// bank core integrations permissions
export const PERM_CORE_SUBMIT_ALL = 'CORE_SUBMIT_ALL'

// provider account reports permissions
export const PERM_ACCT_QUERY_ALL = 'ACCT_QUERY_ALL'
export const PERM_ACCT_QUERY_OWN = 'ACCT_QUERY_OWN'
export const PERM_ACCT_WRITE_ALL = 'ACCT_WRITE_ALL'

// provider funding request permissions
export const PERM_FUND_REQ_QUERY_ALL = 'FUND_REQ_QUERY_ALL'
export const PERM_FUND_REQ_QUERY_OWN = 'FUND_REQ_QUERY_OWN'
export const PERM_FUND_REQ_WRITE_ALL = 'FUND_REQ_WRITE_ALL'

// api integrations resource permissions
export const PERM_INT_QUERY_ALL = 'INT_QUERY_ALL'
export const PERM_INT_QUERY_OWN = 'INT_QUERY_OWN'
export const PERM_INT_WRITE_ALL = 'INT_WRITE_ALL'

// providers resource permissions
export const PERM_PROVIDERS_QUERY_ALL = 'PROV_QUERY_ALL'
export const PERM_PROVIDERS_QUERY_OWN = 'PROV_QUERY_OWN'
export const PERM_PROVIDERS_WRITE_ALL = 'PROV_WRITE_ALL'

// reports resource permissions
export const PERM_REPORTS_QUERY_ALL = 'REPORTS_QUERY_ALL'
export const PERM_REPORTS_QUERY_OWN = 'REPORTS_QUERY_OWN'

// tenants resource permissions
// - should only be accessible to administrative clients
export const PERM_TENANTS_QUERY_ALL = 'TENANTS_QUERY_ALL'
export const PERM_TENANTS_WRITE_ALL = 'TENANTS_WRITE_ALL'

// users resource permissions
export const PERM_USERS_QUERY_ALL = 'USERS_QUERY_ALL'
export const PERM_USERS_QUERY_OWN = 'USERS_QUERY_OWN'
export const PERM_USERS_WRITE_ALL = 'USERS_WRITE_ALL'
export const PERM_USERS_WRITE_OWN = 'USERS_WRITE_OWN'

/**
 *
 *
 *
 *
 */

export const DRAFT_STATUS = 'DRAFT'
export const REQUIRES_REVIEW_STATUS = 'REQUIRES REVIEW'
export const READY_TO_SEND_STATUS = 'READY FOR BATCH'
export const SENT_STATUS = 'IN PROCESS'
export const ARCHIVED_STATUS = 'ARCHIVED'
export const APPROVED_STATUS = 'APPROVED'
export const DECLINED_STATUS = 'DECLINED'
export const ON_HOLD_STATUS = 'ON HOLD'

export const CUSTOMER_USER_TYPE = 'customer'
export const REFERRER_USER_TYPE = 'referrer'

export const APPLICATIONS_COLLECTION_NAME = 'applications'
export const APPLICATION_FILES_FOLDER_NAME = 'applications'
export const PARTICIPANT_FILES_FOLDER_NAME = 'participants'
export const PARTICIPANTS_COLLECTION_NAME = 'participants'
export const REFERRERS_COLLECTION_NAME = 'settings/referrers/list'
export const SETTINGS_COLLECTION_NAME = 'settings'
export const REFERRERS_DOCUMENT = 'referrers'
export const REFFERERS_SUBCOLLECTION = 'list'

export const APPLICATION_STATUS_FIELD = 'status'
export const ENCRYPTED_COLLECTION_NAME = 'encrypted'
export const API_USERS_COLLECTION = 'api-users'

export const SOLERA = 'solera'

export const EMAIL_WELCOME = 'welcome'
export const EMAIL_W8BEN = 'w8ben'

export const SS4_DOC_FILENAME = 'ss4'
export const TRUST_DOC_FILENAME = 'plan_adoption'
export const LLC_OPERATING_DOC_FILENAME = 'llc_operating_agreement'
export const LLC_ARTICLES_DOC_FILENAME = 'llc_articles_of_organization'
export const GREENCARD_DOC_FILENAME = 'green_card'
export const W8BEN_DOC_FILENAME = 'W8_BEN'
export const ID_IMAGE_FRONT_FILENAME = 'id_front'
export const ID_IMAGE_BACK_FILENAME = 'id_back'
export const ID_IMAGE_FACE_FILENAME = 'face'

export const ACCESS_TOKEN_EXPIRY_SECONDS = 3600 // 1 HR

export const AUTHORITY_KEY_APPLICATION = 'providerSource'
export const AUTHORITY_KEY_PARTICIPANT = 'applicationProvider'

export const APPLICATION_ENCRYPTED_KEYS: { [key: string]: string } = {
  'llcTaxIdRef': 'llcEin',
  'trustTaxIdRef': 'trustEin',
  'inheritedIraHolderSsnRef': 'inheritedIraHolderSsn',
  'designatedBeneficiaries.ssnRef': 'ssn',
  'designatedBeneficiaries.taxIdRef': 'taxId'
}

export const PARTICIPANT_ENCRYPTED_KEYS: { [key: string]: string } = {
  ssnRef: 'ssn'
}

export const TOKEN_EXPIRED_ERROR_NAME = 'TokenExpiredError'
export const TOKEN_SIGNATURE_ERROR_NAME = 'JsonWebTokenError'
export const TOKEN_NOT_IN_DATABASE_MESSAGE = 'Token not found in database'

export const EXTRA_PARTICIPANT_FIELDS_TO_PURGE = [
  'previousAddressObj',
  'jumioTransaction',
  'completedSections',
  'mailingAddressObj',
  'resAddressObj',
  'identityJumioCallback',
  'lastEditedStep',
  'IPAddress'
]
export const EXTRA_APPLICATION_FIELDS_TO_PURGE = [
  'previousAddressObj',
  'jumioTransaction',
  'completedSections',
  'mailingAddressObj',
  'resAddressObj',
  'identityJumioCallback',
  'lastEditedStep',
  'IPAddress'
]

export const STORAGE_DOC_NAMES: {
  [k: string]: { [k: string]: Array<{ filename: string; dbKey: string }> }
} = {
  applications: {
    trust: [{ filename: TRUST_DOC_FILENAME, dbKey: 'trustStorageRef' }],
    llc: [
      {
        filename: LLC_OPERATING_DOC_FILENAME,
        dbKey: 'llcOperatingAgreementRef'
      },
      {
        filename: LLC_ARTICLES_DOC_FILENAME,
        dbKey: 'llcArticlesOfOrganizationRef'
      }
    ]
  },
  participants: {
    identity: [
      { filename: GREENCARD_DOC_FILENAME, dbKey: 'greenCardStorageRef' },
      { filename: W8BEN_DOC_FILENAME, dbKey: 'w8BENStorageRef' }
    ]
  }
}
